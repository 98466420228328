interface ITimeComposable {
   useDateTime: (
      inputDate: string | number | Date | undefined | null,
      options?: { format?: string; locale?: string }
   ) => string;
   useTimeFromNow: (inputDate: string | Date | undefined | null) => string;
   useDaysFromNow: (inputDate: string | Date | undefined | null) => string;
   useTimeFormat: (
      inputDate: string | number | Date | undefined | null
   ) => string;
   useDate: (
      inputDate: string | number | Date | undefined | null
   ) => Date | null;
}
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const useTimeComposable = (): ITimeComposable => {
   /*
    * -----------------------------------------------------------------------
    * Methods
    * -----------------------------------------------------------------------
    * Description: Returns a formatted date string based on the input date
    * @param inputDate: string | Date | undefined|null
    * @param options: Intl.DateTimeFormatOptions
    * @returns string
    * -----------------------------------------------------------------------
    */
   const useDateTime = (
      inputDate: string | number | Date | undefined | null,
      options?: { format?: string; locale?: string }
   ): string => {
      const locale = useCookie('i18n_redirected');
      if (!inputDate) {
         return '';
      }
      return dayjs
         .utc(inputDate)
         .tz(useTimeZoneStore().currentClientTimezone)
         .locale(options?.locale ?? locale.value ?? 'en')
         .format(options?.format || 'MMM DD, YYYY ');
   };
   const useDate = (
      inputDate: string | number | Date | undefined | null
   ): Date | null => {
      const locale = useCookie('i18n_redirected');
      if (!inputDate) {
         return null;
      }
      return dayjs
         .utc(inputDate)
         .tz(useTimeZoneStore().currentClientTimezone)
         .locale(locale.value ?? 'en')
         .toDate();
   };
   /*
    * -----------------------------------------------------------------------
    * Methods
    * -----------------------------------------------------------------------
    * Description: Returns a formatted time string based on the input date
    * @param inputDate: string | Date | undefined|null
    * @returns string
    * -----------------------------------------------------------------------
    * @example
    * useTimeFromNow(new Date()) // just now
    * -----------------------------------------------------------------------
    * @example
    * useTimeFromNow(new Date().setMinutes(new Date().getMinutes() - 5)) // 5 minutes ago
    * -----------------------------------------------------------------------
    * @example
    * useTimeFromNow(new Date().setHours(new Date().getHours() - 1)) // 1 hour ago
    */
   const useTimeFromNow = (
      inputDate: string | Date | undefined | null
   ): string => {
      const { t } = useI18n();
      if (!inputDate) {
         return '';
      }
      // calculate the difference between now and the input date in seconds and round it
      const diff = Math.round(
         (new Date().getTime() - new Date(inputDate).getTime()) / 1000
      );
      // if the difference is less than a minute
      if (diff < 60) {
         return t('time.justNow');
      }

      // if the difference is less than an hour
      if (diff < 3600) {
         const minutes = Math.round(diff / 60);
         return t('time.minutesAgo', { count: minutes });
      }

      // if the difference is less than a day
      if (diff < 86400) {
         const hours = Math.round(diff / 3600);
         return t('time.hoursAgo', { count: hours });
      }

      // if the difference is less than a month
      if (diff < 2592000) {
         const days = Math.round(diff / 86400);
         return t('time.daysAgo', { count: days });
      }

      // return the date in a readable format
      return useDateTime(inputDate);
   };
   const useDaysFromNow = (
      inputDate: string | Date | undefined | null
   ): string => {
      const { t } = useI18n();
      if (!inputDate) {
         return '';
      }
      // calculate the difference between now and the input date in seconds and round it
      const diff = Math.round(
         (new Date().getTime() - new Date(inputDate).getTime()) / 1000
      );
      // if the difference is less than a day
      if (diff < 86400) {
         const hours = Math.round(diff / 3600);
         return t('time.today');
      }

      // if the difference is less than a month
      if (diff < 172800) {
         return t('time.yesterday');
      }

      // return the date in a readable format
      return useDateTime(inputDate);
   };

   const useTimeFormat = (
      inputDate: string | number | Date | undefined | null
   ): string => {
      if (!inputDate) {
         return '';
      }
      const locale = useCookie('i18n_redirected');

      return dayjs
         .utc(inputDate)
         .tz(useTimeZoneStore().currentClientTimezone)
         .locale(locale.value ?? 'en')
         .format('hh:mm A');
   };

   return {
      useDateTime,
      useTimeFromNow,
      useDaysFromNow,
      useTimeFormat,
      useDate,
   };
};

export default useTimeComposable;
